var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wuliaoDetail" },
    [
      _c("van-nav-bar", {
        attrs: {
          "left-arrow": "",
          fixed: "",
          "safe-area-inset-top": "",
          placeholder: "",
          title: "物料详情"
        },
        on: { "click-left": _vm.leftClick }
      }),
      _vm.isLoading
        ? _c(
            "div",
            [
              _c(
                "center",
                { attrs: { id: "loadingbox" } },
                [_c("van-loading", { attrs: { size: "68px" } })],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.isLoading
        ? _c(
            "div",
            { staticClass: "body" },
            [
              _c("div", { staticClass: "top" }, [
                _c(
                  "div",
                  {
                    staticStyle: { "font-size": "32px", "font-weight": "500" }
                  },
                  [_vm._v(" " + _vm._s(_vm.GLOBAL) + "检测中心 ")]
                ),
                _c("div", [_vm._v("Testing Center")]),
                _c("div", { staticClass: "line" }),
                _c("div", { staticClass: "flex_between" }, [
                  _c("div", [
                    _vm._v("检测编号: " + _vm._s(_vm.incomingDetail.incomingNo))
                  ]),
                  _c("div", [
                    _vm._v(
                      " 检测日期: " +
                        _vm._s(
                          _vm._f("formatDate_")(
                            _vm.incomingDetail.created,
                            "yyyy-MM-dd"
                          )
                        ) +
                        " "
                    )
                  ])
                ])
              ]),
              _vm._l(_vm.incomingDetail.subDetails, function(items, indexs) {
                return _c(
                  "div",
                  { key: indexs },
                  _vm._l(items.content, function(item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "detail flex" },
                      [
                        _c("div", { staticClass: "left" }, [
                          _c("div", { staticClass: "block" }, [
                            _c("div", { staticClass: "lable" }, [
                              _vm._v("检测物:")
                            ]),
                            _c("div", { staticClass: "value" }, [
                              _vm._v(_vm._s(item.goodsName))
                            ])
                          ]),
                          _c("div", { staticClass: "block" }, [
                            _c("div", { staticClass: "lable" }, [
                              _vm._v("折足(g):")
                            ]),
                            _c("div", { staticClass: "value" }, [
                              _vm._v(_vm._s(item.discountWeight))
                            ])
                          ]),
                          _c("div", { staticClass: "block" }, [
                            _c("div", { staticClass: "lable" }, [
                              _vm._v("预估含量(%):")
                            ]),
                            _c("div", { staticClass: "value" }, [
                              _vm._v(_vm._s(item.estimatePercent))
                            ])
                          ])
                        ]),
                        _c("div", { staticClass: "middle" }, [
                          _c("div", { staticClass: "block" }, [
                            _c("div", { staticClass: "lable" }, [
                              _vm._v("净重(g):")
                            ]),
                            _c("div", { staticClass: "value" }, [
                              _vm._v(_vm._s(item.afterWeight))
                            ])
                          ]),
                          _c("div", { staticClass: "block" }, [
                            _c("div", { staticClass: "lable" }, [
                              _vm._v("含量(%):")
                            ]),
                            _c("div", { staticClass: "value" }, [
                              _vm._v(_vm._s(item.percent))
                            ])
                          ])
                        ]),
                        _c(
                          "div",
                          { staticClass: "right" },
                          [
                            _c(
                              "van-swipe",
                              {
                                attrs: { "indicator-color": "red", width: 220 }
                              },
                              [
                                item.images && item.images.length !== 0
                                  ? _vm._l(item.images, function(
                                      img,
                                      imgIndex
                                    ) {
                                      return _c(
                                        "van-swipe-item",
                                        { key: imgIndex },
                                        [
                                          _c("img", {
                                            attrs: { src: img.url, alt: "" }
                                          })
                                        ]
                                      )
                                    })
                                  : [
                                      _c("van-swipe-item", [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/images/pho_com_nor@2x.png"),
                                            alt: ""
                                          }
                                        })
                                      ])
                                    ]
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ]
                    )
                  }),
                  0
                )
              }),
              _c("div", { staticClass: "detail flex" }, [
                _c("div", { staticClass: "left" }, [
                  _c("div", { staticClass: "block" }, [
                    _c("div", { staticClass: "lable" }, [
                      _vm._v("来料重量(g):")
                    ]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.incomingDetail.totalWeight))
                    ])
                  ]),
                  _c("div", { staticClass: "block" }, [
                    _c("div", { staticClass: "lable" }, [_vm._v("检测人:")]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.incomingDetail.admin.name))
                    ])
                  ])
                ]),
                _c("div", { staticClass: "middle" }, [
                  _c("div", { staticClass: "block" }, [
                    _c("div", { staticClass: "lable" }, [
                      _vm._v("折足重量(g):")
                    ]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.incomingDetail.totalDiscountWeight))
                    ])
                  ]),
                  _c("div", { staticClass: "block" }, [
                    _c("div", { staticClass: "lable" }, [_vm._v("复核人:")]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(
                        _vm._s(
                          _vm.incomingDetail.auditAdmin
                            ? _vm.incomingDetail.auditAdmin.name
                            : "--"
                        )
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "right kefu" }, [
                  _c("div", { staticClass: "kefuwechat" }, [
                    _c("img", {
                      staticStyle: { width: "60px", height: "60px" },
                      attrs: { src: _vm.kefuImg, alt: "" }
                    })
                  ]),
                  _c("div", [_vm._v("客服微信")]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v("如对检测结果存在异议请尽快联系客服")
                  ]),
                  _vm._m(0)
                ])
              ]),
              _vm.incomingDetail.referenceStatus !== "REJECT"
                ? _c(
                    "el-button",
                    {
                      staticClass: "btnsee f32",
                      attrs: {
                        disabled:
                          _vm.incomingDetail.referenceStatus == "CONFIRMED" ||
                          _vm.incomingDetail.referenceStatus == "TICHUN" ||
                          _vm.incomingDetail.referenceStatus == "TICHUNING"
                      },
                      on: { click: _vm.makeSure }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.incomingDetail.referenceStatus !== "WAIT_CONFIRM"
                            ? "已确认"
                            : "确认无误"
                        )
                      )
                    ]
                  )
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm.incomingDetail.referenceInfoList && _vm.backPath === "incoming"
        ? _c("div", { staticClass: "referenceInfo" }, [
            _vm._m(1),
            _c(
              "div",
              { staticClass: "list" },
              [
                _vm._m(2),
                _vm._l(_vm.incomingDetail.referenceInfoList, function(
                  item,
                  index
                ) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "listContent flex_between",
                      staticStyle: { height: "34px", "line-height": "34px" }
                    },
                    [
                      _c("div", { staticClass: "time" }, [
                        _vm._v(
                          _vm._s(_vm._f("formatDate_")(item.time, "yyyy-MM-dd"))
                        )
                      ]),
                      _c("div", { staticClass: "price" }, [
                        _vm._v(_vm._s(item.goldReferenceWeight))
                      ]),
                      _c("div", { staticClass: "price" }, [
                        _vm._v(_vm._s(item.baiyinReferenceWeight))
                      ]),
                      _c("div", { staticClass: "price" }, [
                        _vm._v(_vm._s(item.ptReferenceWeight))
                      ]),
                      _c("div", { staticClass: "price" }, [
                        _vm._v(_vm._s(item.pdReferenceWeight))
                      ]),
                      _c("div", { staticClass: "price" }, [
                        _vm._v(
                          _vm._s(
                            item.openingType === "SELL"
                              ? "卖出"
                              : item.openingType === "MORTGAGE" ||
                                item.openingType === "PRE_MORTGAGE"
                              ? "存料"
                              : item.openingType === "CHANGE_MATERIAL"
                              ? "换料"
                              : item.openingType
                          )
                        )
                      ])
                    ]
                  )
                })
              ],
              2
            )
          ])
        : _vm._e(),
      _vm._m(3)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "flex", staticStyle: { "margin-top": "2px" } },
      [
        _c("div", {
          staticClass: "img",
          staticStyle: { height: "12px", width: "12px", "margin-top": "2px" }
        }),
        _c("div", { staticClass: "phone" }, [_vm._v("18126327787")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "costCalculation" }, [
      _c("div", { staticClass: "icon" }),
      _c("h3", [_vm._v("引用明细")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "listContent flex_between",
        staticStyle: { height: "34px", "line-height": "34px" }
      },
      [
        _c("div", { staticClass: "time" }, [_vm._v("引用时间")]),
        _c("div", { staticClass: "price" }, [_vm._v("黄金")]),
        _c("div", { staticClass: "price" }, [_vm._v("白银")]),
        _c("div", { staticClass: "price" }, [_vm._v("铂金")]),
        _c("div", { staticClass: "price" }, [_vm._v("钯金")]),
        _c("div", { staticClass: "price" }, [_vm._v("结算类型")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tip" }, [
      _c("div", [
        _vm._v("检测依据：GB/T 16554 GB/T 18043 GB/T 7724 GB/T 11887")
      ]),
      _c("div", [_vm._v("计量标准：GB/T 7724-2008")]),
      _c("div", [_vm._v("国家标准：GB 11887-2002规定")]),
      _c("div", [_vm._v("光普检测标准火试法：GB/T 18043-2013")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }